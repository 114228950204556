import React from 'react'
import { ReactComponent as HomeIcon } from '../assets/pic/logo-home.svg';
import { ReactComponent as SearchIcon } from '../assets/pic/search-icon.svg';
import { ReactComponent as BurgerMenuIcon } from '../assets/pic/burger-menu-icon.svg';
import { ReactComponent as UserIcon } from '../assets/pic/user-icon.svg';
import { ReactComponent as CartIcon } from '../assets/pic/cart-icon.svg';
import { COMPANYNAME as NEWCOMPANYNAME, VERSION } from '../constants';
import { Text } from '../languages/Language';
import Impressum from './Impressum';
import Datenschutz from './Datenschutz';
import AGB from './AGB';
import Contact from './Contact';
import AboutUs from './AboutUs';
import foresLogo from '../assets/pic/fores-logo.png';


export default function Footer(props) {
  return (

    <div className="hidden flex-col items-center justify-center w-full h-full text-very-small md:text-xs font-medium p-1 bg-menu-secondary-color text-white border-t drop-shadow-2xl md:flex md:items-center
     md:justify-between dark:bg-gray-800">

<div className='flex flex-row w-full h-full items-center justify-center'>
      <div className="flex h-full  justify-between mx-8 w-full items-center">
        <div className="flex flex-row w-auto items-center justify-between text-white gap-2 ">

          <p><Impressum ShowImpressum={(value) => props.ShowImpressum(value)}/></p>
          <p>|</p>
          <p><Datenschutz ShowDatenschutz={(value) => props.ShowDatenschutz(value)}/></p>
          <p>|</p>
          <p><AGB ShowAGB={(value) => props.ShowAGB(value)}/></p>
          <p>|</p>
          <p><Contact ShowContact={(value) => props.ShowContact(value)}/></p>
          <p>|</p>
          <p><AboutUs ShowAboutUs={(value) => props.ShowAboutUs(value)}/></p>
        </div>
      { /*  <div className="flex flex-row text-center items-center">
          <button className=" border-b border-company-primary-color text-sm  hover:underline md:mr-6 dark:text-gray-400">
          <Text tid="MobilApplications" /></button>
          <div className="flex flex-row">
              <img src={AppStoreLogo} className="w-24 h-12" />
                  <img src={PlayStoreLogo} className="w-36" />
        </div>
          </div>*/}
        <div className="flex flex-col h-full items-center justify-center">
          <div className='flex flex-row  text-very-small'>
            <span className="flex dark:text-slate-400">
             {VERSION} Copyrights © <Text tid="AllRightsReserved" />
            </span>
          </div>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://fores.de/"
            className="p-1 bg-white rounded-md flex items-center gap-2 mt-1" >
            <img src={foresLogo} height={10} width={80} alt="Fores Logo" />
          </a>

        </div>
      </div>
      <div className="min-h-screen hidden">
        <div className="relative hidden lg:block">
          <div className="relative">
            <div className="swiper swiper-initialised swiper-horizontal">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="relative h-screen w-full">
                    <a
                      className="leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
                      href="mailto:"
                    >
                      |
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex visible w-full h-12  md:h-14 sm:hidden">
        <nav className="fixed bottom-0 z-50 flex h-12 w-full justify-between bg-white py-1.5 px-2
        shadow-400 ltr:left-0 rtl:right-0 md:h-14">
          <button className="flex h-full items-center justify-center p-2 focus:text-accent focus:outline-none">
            <span className="sr-only">Burger Menu</span><BurgerMenuIcon /></button>
          <button className="flex items-center justify-center h-full p-2 focus:outline-none focus:text-accent">
            <span className="sr-only">Search</span><SearchIcon /></button>
          <button className="flex h-full items-center justify-center p-2 focus:text-accent focus:outline-none">
            <span className="sr-only">Home</span><HomeIcon /></button>
          <button className="relative flex  h-12 items-center justify-center p-2 focus:text-accent focus:outline-none">
            <span className="sr-only">Cart</span><CartIcon /><span className="absolute top-0 mt-0.5
            rounded-full bg-accent py-1 px-1.5 text-10px font-semibold leading-none text-light
            ltr:right-0 ltr:-mr-0.5 rtl:left-0 rtl:-ml-0.5">1</span>
          </button>
          <button className="flex h-full items-center justify-center p-2 focus:text-accent focus:outline-none" >
            <span className="sr-only">User</span><UserIcon />
          </button>

        </nav>
      </div>
    </div>
    </div>

  )
}
