
export const CURRENCY =  '€';
export const DFTLNGCODE =  'nl';
export const DIGITS = 2;
export const URL = 'https://api.fbsshop.de';
export const IMAGEURL = '/images';
export const PAYMENTSERVER = 'https://pay.mobiserver.de:4242';
export const VENDORID = '10126001';
export const COMPANYNAME = 'Fores GmbH'
export const VERSION = "Version 2.3.0"
export const PAYMENTSYSTEM = false;
export const BANNER = false;
export const BRANDS = false;
export const FLAG_ITEM_ON_HAND = false;
